import './Portfolio.css';
import { Desktop, Mobile, MobileFooter } from '../../common/Common';
import { images } from './images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Panel, { isShiftLeft } from 'common/panel/Panel';
import Watermark from 'common/Watermark';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Portfolio = () => {
  
  return (
    <>
      <Desktop><PortfolioDesktop/></Desktop>
      <Mobile><PortfolioMobile/></Mobile> 
    </>
  );
}

const PortfolioContent = ({setModalImage}) => {
  return (
    <>
      {images.map((image, index) => {
        return (
          <div key={index} className='image-square'>
            <LazyLoadImage 
              key={index} 
              className='portfolio-image' 
              src={String(image.thumbnail)} 
              alt={image.alt} 
              onClick={() => setModalImage({ path: String(image.hiRes), alt: image.alt })}
            />
          </div>
        );
      })}
    </>
  )
}

const PortfolioDesktop = () => {
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    const closeModal = (e) => {
      if (e.key === 'Escape') {
        setModalImage(null);
      }
    }
    window.addEventListener('keydown', closeModal);
    return () => window.removeEventListener('keydown', closeModal)
  }, []);

  useEffect(() => {
    document.body.classList.toggle('modal-image-shown', Boolean(modalImage));
  }, [modalImage]);

  const location = useLocation();
  return (
    <>
      <Watermark />
      <Panel />
      <div 
        className={`desktop-main desktop-main__fade-in${isShiftLeft(location) ? '-shift' : ''}`}
      >
        <div id='portfolio-desktop' className='desktop-main__content'>
          <PortfolioContent setModalImage={setModalImage} />
        </div>
      </div>
      { modalImage &&
        <>
          <div className='image-modal'>
            <div className='image-modal__background' onClick={() => setModalImage(null)} />
            <div className='image-modal__exit' onClick={() => setModalImage(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <img src={modalImage.path} alt={modalImage.alt} />
          </div>
        </>
      }
    </>
  )
}

const PortfolioMobile = () => {
  const [modalImage, setModalImage] = useState(null);
  
  useEffect(() => {
    document.body.classList.toggle('modal-image-shown', Boolean(modalImage));
  }, [modalImage]);

  return (
    <>
      <Watermark />
      <Panel />
      <div id='portfolio-mobile' className='mobile-main__content'>
        <PortfolioContent setModalImage={setModalImage} />
      </div>
      { modalImage &&
        <>
          <div className='image-modal image-modal-mobile'>
            <div className='image-modal__background' onClick={() => setModalImage(null)} />
            <div className='image-modal__exit' onClick={() => setModalImage(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <img src={modalImage.path} alt={modalImage.alt} />
          </div>
        </>
      }
      <MobileFooter />
    </>
  )
}

export default Portfolio;