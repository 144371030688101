import './Navigation.css';
import { Desktop, Mobile } from '../common/Common';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import { Socials } from 'common/Socials';

const Navigation = () => {
  const location = useLocation();

  return (
    <>
      <Desktop><NavigationDesktop location={location}/></Desktop>
      <Mobile><NavigationMobile location={location} /></Mobile>
    </>
  );
}

const NavigationDesktop = ({ location }) => {
  return (
    <nav className='desktop-navigation'>
      <ul>
        <li>
          <Link to="/" state={{ prevPath: location.pathname }} className={location.pathname === '/' ? 'nav-link__active' : ''}>home</Link>
        </li>
        <li>
          <Link to="/about" state={{ prevPath: location.pathname }} className={location.pathname === '/about' ? 'nav-link__active' : ''}>about</Link>
        </li>
        <li>
          <Link to="/booking" state={{ prevPath: location.pathname }} className={location.pathname === '/booking' ? 'nav-link__active' : ''}>booking</Link>
        </li>
        <li>
          <Link to="/portfolio" state={{ prevPath: location.pathname }} className={location.pathname === '/portfolio' ? 'nav-link__active' : ''}>folio</Link>
        </li>
        <li>
          <Link to="/care" state={{ prevPath: location.pathname }} className={location.pathname === '/care' ? 'nav-link__active' : ''}>care</Link>
        </li>
        <li>
          <Link to="/faq" state={{ prevPath: location.pathname }} className={location.pathname === '/faq' ? 'nav-link__active' : ''}>faq</Link>
        </li>
      </ul>
    </nav>
  )
}

const NavigationMobile = ({ location }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('mobile-nav-expanded', isExpanded);
  }, [isExpanded]);

  return (
    <>
      <nav className='mobile-navigation'>
        <div className='mobile-navigation__header'>
          <Link to='/' onClick={() => setIsExpanded(false)} >
            <img className='mobile-navigation__header-logo' src={logo} alt={'wistaria logo links to home page'} />
          </Link>
          <button 
            className='menu-hamburger' 
            onClick={() => setIsExpanded(!isExpanded)}
          >
            { isExpanded ?
              /* x icon */
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
              :
              /* menu icon */
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            }
          </button>
        </div>
        <div className={`mobile-navigation__menu ${isExpanded ? 'expanded' : ''}`}>
          <ul>
            <li>
              <Link to="/" onClick={() => setIsExpanded(false)} className={location.pathname === '/' ? 'nav-link__active' : ''}>home</Link>
            </li>
            <li>
              <Link to="/about" onClick={() => setIsExpanded(false)} className={location.pathname === '/about' ? 'nav-link__active' : ''}>about</Link>
            </li>
            <li>
              <Link to="/booking" onClick={() => setIsExpanded(false)} className={location.pathname === '/booking' ? 'nav-link__active' : ''}>booking</Link>
            </li>
            <li>
              <Link to="/portfolio" onClick={() => setIsExpanded(false)} className={location.pathname === '/portfolio' ? 'nav-link__active' : ''}>folio</Link>
            </li>
            <li>
              <Link to="/care" onClick={() => setIsExpanded(false)} className={location.pathname === '/care' ? 'nav-link__active' : ''}>care</Link>
            </li>
            <li>
              <Link to="/faq" onClick={() => setIsExpanded(false)} className={location.pathname === '/faq' ? 'nav-link__active' : ''}>faq</Link>
            </li>
          </ul>
          <Socials />
        </div>
      </nav>
    </>
  )
}

export default Navigation;