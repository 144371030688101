export const images = [
  {
    thumbnail: require('../../assets/flashes/flash-1-small.png'),
    hiRes: require('../../assets/flashes/flash-1.png'),
    alt: `flash #001 a page of black lineart fall fruit flash with blue text. from left to right: 1) hawthorn; 2) acorns 2a and 2b; 3) pineapple guava; 4) maple; 5) pomegranates`
  },
  {
    thumbnail: require('../../assets/flashes/flash-2a-small.png'),
    hiRes: require('../../assets/flashes/flash-2a.png'),
    alt: `flash #002 page 1 of 2, with black lineart of plant cuttings and text in green. From left to right: 1) Monstera adansonii; 2) baby Monstera deliciosa; 3) Philodendron 'Florida Beauty' 4) Philodendron chordatum 4a. is backwards 4b. is forwards; 5) Scindapsus pictus`
  },
  {
    thumbnail: require('../../assets/flashes/flash-2b-small.png'),
    hiRes: require('../../assets/flashes/flash-2b.png'),
    alt: `flash #002 page 2 of 2, with black lineart of plant cuttings in jars and text in green. The jars are labelled 6-9 and in text reads "+ jars: no. 6-9 are for jars only, not the plants)`
  },
  {
    thumbnail: require('../../assets/flashes/flash-3-small.png'),
    hiRes: require('../../assets/flashes/flash-3.png'),
    alt: `flash #003 with two black lineart drawings and text in orange. From left to right: 1) magnolia sp.; 2) chaenomeles sp.`
  },
  {
    thumbnail: require('../../assets/flashes/flash-4-small.png'),
    hiRes: require('../../assets/flashes/flash-4.png'),
    alt: `flash #004 with one black lineart drawing of a Raphidophora tetrasperma. They are a curving vine of leaves going diagonally up to the left, with 5 leaves at various angles and a 6th one at the top about to unfurl.`
  },
  {
    thumbnail: require('../../assets/flashes/flash-5-small.png'),
    hiRes: require('../../assets/flashes/flash-5.png'),
    alt: `flash #005 A page of black lineart drawings against a paper textured pale grey background. On the top right in red handwritten text is “flash #005; Scoliopus bigelovii; California fetid adderstongue”. From left to right are the three flower drawings, all with showy sepals with black stripes and thin petals that curl up and outwards. 4a) is just the flower facing the right with a piece of the stem trailing out underneath. 4b) is a left facing flower snaking from underneath a pair of polka dotted leaves that gives the plant their name of adderstongue. 4c) is a left facing flower with the stem making a loop and connecting with an unopened bud on the right.`
  }
];
