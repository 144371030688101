import './Panel.css';
import { Desktop, Mobile } from '../Common';
import { useLocation } from 'react-router-dom';
import { Socials } from 'common/Socials';

const Panel = () => {
  const location = useLocation();
  const path = location.pathname;
  const isHome = path && cleanPath(path) === '';

  return (
    <>
      <Desktop><PanelDesktop location={location} path={path} isHome={isHome}/></Desktop>
      <Mobile><PanelMobile path={path} isHome={isHome}/></Mobile>
    </>
  );
}

export const isShiftRight = location => {
  const prevPath = location.state ? location.state.prevPath : null;
  const path = location.pathname;
  return prevPath && path && cleanPath(path) === '';
}

export const isShiftLeft = location => {
  const prevPath = location.state ? location.state.prevPath : null;
  return prevPath && prevPath === '/';
}

export const cleanPath = path => {
  return path.substring(1);
}

const SubHeader = ({subheader, device}) => {
  return ( 
    <div className={`panel-${device}__subheader subheader-fade-in`}>
      <span className='subheader-decorator'>❊</span>{` ${subheader} `}<span className='subheader-decorator'>❊</span>
    </div>
  );
}

const PanelDesktop = ({ location, path, isHome }) => {
  const prevPath = location.state ? location.state.prevPath : null;

  // refresh the state after using so refresh doesn't re-use
  window.history.replaceState({}, '');

  let animatePanel = '';
  if (prevPath && isHome) {
    animatePanel = 'animate-home-in';
  }
  else if (prevPath && prevPath === '/') {
    animatePanel = 'animate-home-out';
  }

  return (
    <div id='panel-desktop__main' className={`panel-${isHome ? 'home-' : ''}width ${animatePanel}`}>
      <div id='panel-desktop__background' />
      <div id={`panel-desktop${isHome ? '-home' : ''}__header-section`}>
        <p id='panel-desktop__header'>
          wistaria.ink
        </p>
        { path && !isHome &&
          <SubHeader subheader={cleanPath(path)} device={'desktop'}/>
        }
      </div>
      <div id='panel-desktop__icons'>
        <Socials />
      </div>
    </div>
  )
}

const PanelMobile = ({ path, isHome }) => {
  return (
    <>
      <div className='panel-mobile__main'>
        <div className='panel-mobile__background' />
        <div id={`panel-mobile${isHome ? '-home' : ''}__header-section`}>
          <p id='panel-mobile__header'>
            wistaria.ink
          </p>
          { path && !isHome &&
            <SubHeader subheader={cleanPath(path)} device={'mobile'} />
          }
        </div>
      </div>
    </>
  )
}

export default Panel;