import './Care.css';
import { Desktop, Mobile } from '../../common/Common';
import Panel, { isShiftLeft } from 'common/panel/Panel';
import Watermark from 'common/Watermark';
import { Socials } from 'common/Socials';
import { useLocation } from 'react-router-dom';

const Care = () => {
  return (
    <>
      <Desktop><CareDesktop/></Desktop>
      <Mobile><CareMobile/></Mobile> 
    </>
  );
}

const CareContent = () => {
  return (
    <>
      <i>Tattoos are all about self care! Preparation starts weeks before your tattoo, there is a critical two weeks following your appointment, and honestly carries on through the rest of your life :) It’s best to schedule your appointment while being mindful to make time for the recovery period that follows. That looks like finding a time when you are not going to the pool or beach right after, or when you can avoid doing strenuous exercise.</i>
      <h3>PRE-CARE</h3>
      <ul>
        <li>2 weeks prior</li>
        <ul>
          <li>Practice good skin care on your tattoo area, a moisturized canvas is the best canvas. Apply any kind of lotion 1-2x a day.</li>
          <li>If you rigorously work out your tattoo area on a daily basis, try to limit that activity for two weeks.</li>
          <li>Drink lots of water!</li>
        </ul>
        <li>Day before</li>
        <ul>
          <li>No ibuprofen, no excessive alcohol</li>
          <li>Drink lots of water!</li>
          <li>Get good rest!</li>
        </ul>
        <li>Day of</li>
        <ul>
          <li>Have a GOOD MEAL right before your appointment, do not go on an empty stomach.</li>
          <li>No ibuprofen, no alcohol, no excessive caffeine. If you need pain medication, take it after your appointment.</li>
          <li>Wear comfortable, loose clothes in the area of tattoo.</li>
          <li>Drink lots of water!</li>
        </ul>
      </ul>

      <h3>AFTER CARE</h3>
      General thoughts
      <ul>
        <li>Follow your specific artist’s aftercare instructions! Different tattoos/artists have different healing processes.</li>
        <li>Tattoos take approximately 3-5 weeks to heal completely.</li>
        <li>Always wash your hands with soap before touching your healing tattoo.</li>
        <li>Do not let anyone touch your tattoo, unless they wash their hands.</li>
      </ul>
      Right after appointment
      <ul>
        <li>If you received Saniderm/second skin:</li>
        <ul>
          <li>Leave on for 2-3 days if it stays sealed properly. That means no water or debris is going into the saniderm and onto your tattoo. You can shower with the bandage on, immediately following the appointment.</li>
          <li>To remove saniderm, it is best to do under running water like the shower, pulling the bandage slowly away from your skin. (YouTube link)</li>
          <li>Start aftercare after the saniderm comes off.</li>
        </ul>
        <li>If you did NOT receive second skin (latex allergy):</li>
        <ul>
          <li>Do not start aftercare the same day of your appointment.</li>
          <li>Remove dressing the morning after the tattoo and start aftercare.</li>
        </ul>
      </ul>

      First week- daily aftercare following bandage removal, 3-5x daily:
      <ul>
        <li>With clean hands, splash warm water onto your tattoo, lather with unscented hypoallergenic soap, and gently rinse off. Do not use a washcloth or anything that will exfoliate your tattoo. Only use your hands.</li>
        <li>Pat dry with a paper towel or air dry. Do not rub, or use a fabric with a rough surface to dry your tattoo.</li>
        <li>Rub a very thin layer of tattoo ointment or unscented lotion. Be sure to use products you are not allergic to. Always use clean hands and do not double-dip fingers back into the ointment after you’ve touched your tattoo. Do NOT use Vaseline, petroleum, Neosporin, or any products containing Aloe Vera.</li>
        <li>Wear soft or loose clothing over your tattoo. Do not expose the area to sun or chafe against tight clothing or jewelry until your tattoo has finished peeling. Treat it like a bad sunburn basically.</li>
        <li>After 3-4 days, your tattoo will start peeling off. Do not scratch or peel your tattoo! If it is itchy, try slapping the tattoo over your loose clothing. Once you’re done peeling your tattoo will look shinier and incorporated into your skin rather than raised above.</li>
      </ul>

      Second week-
      <ul>
        <li>Continue to apply lotion 3-5x a day.</li>
        <li>Continue to avoid swimming/excessive sweating for this week.</li>
        <li>Continue to avoid exposure to sunlight.</li>
      </ul>

      After 2 weeks
      <ul>
        <li>You can resume your regular skin/exercise routine after 2 weeks. The tattoo is considered fully healed after 2 months, so it is best to avoid sunlight or to apply sunblock until then.</li>
      </ul>

      Signs of Infection (to seek medical care):
      <ul>
        <li>Purulent drainage from the procedure site</li>
        <li>High fever especially over 102°F</li>
        <li>Prolonged or severe pain, redness, swelling or tenderness</li>
        <li>Sores that contain or release thick, white or yellow fluid</li>
        <li>Muscle aches and pain affecting the whole body</li>
        <li>Extreme or unquenchable thirst</li>
        <li>Hard, red, raised bumps or wounds</li>
        <li>Red streaks moving towards the heart</li>
        <li>Diarrhea</li>
        <li>Nausea and/or vomiting</li>
      </ul>
    </>
  )
}

const CareDesktop = () => {
  const location = useLocation();
  return (
    <>
      <Watermark />
      <Panel />
      <div 
        id='care__page'
        className={`desktop-main desktop-main__fade-in${isShiftLeft(location) ? '-shift' : ''}`}
      >
        <div className='desktop-main__content'>
          <CareContent />
        </div>
      </div>
    </>
  )
}

const CareMobile = () => {
  return (
    <>
      <Watermark />
      <Panel />
      <div id='care__page' className='mobile-main'>
        <div className='mobile-main__content'>
          <CareContent />
        </div>
      </div>
      <div className='mobile-footer'>
        <Socials />
      </div>
    </>
  )
}

export default Care;