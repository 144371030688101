import './App.css';
import { createBrowserRouter, useOutlet, Navigate, ScrollRestoration, useLocation } from 'react-router-dom';
import { createRef, useEffect } from 'react';
import Home from './pages/home/Home';
import About from 'pages/about/About';
import Portfolio from 'pages/portfolio/Portfolio';
import Booking from 'pages/booking/Booking';
import Care from 'pages/care/Care';
import Faq from 'pages/faq/Faq';
import Navigation from 'navigation/Navigation';
import { cleanPath } from 'common/panel/Panel';

const routes = [
  { path: '/', name: 'home', element: <Home />, nodeRef: createRef() },
  { path: '/about', name: 'about', element: <About />, nodeRef: createRef() },
  { path: '/booking', name: 'booking', element: <Booking />, nodeRef: createRef() },
  { path: '/portfolio', name: 'portfolio', element: <Portfolio />, nodeRef: createRef() },
  { path: '/care', name: 'care', element: <Care />, nodeRef: createRef() },
  { path: '/faq', name: 'faq', element: <Faq />, nodeRef: createRef() },
  { path: '*', name: '404', element: <NoMatch />, nodeRef: createRef() }
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element
    }))
  }
]);

function App() {
  const currentOutlet = useOutlet();
  const location = useLocation();
  
  useEffect(() => {
    const path = cleanPath(location.pathname);
    const title = path === '' ? 'wistaria.ink' : `${path} | wistaria.ink`;
    document.title = title;
  }, [location.pathname])

  return (
    <div className="App">
      <Navigation />
        {currentOutlet}
      <ScrollRestoration />
    </div>
  );
}

function NoMatch() {
  return (
    <Navigate to="/" replace />
  );
}

export default App;
