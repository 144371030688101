import { Desktop, Mobile } from './Common';
import './Common.css';

const Watermark = () => {
  return (
    <>
      <Desktop><WatermarkDesktop /></Desktop>
      <Mobile><WatermarkMobile /></Mobile>
    </>
  )
}

const WatermarkDesktop = () => {
  return (
    <div id='background__watermark' className='desktop-watermark' />
  )
}

const WatermarkMobile = () => {
  return (
    <div id='background__watermark' className='mobile-watermark' />
  )
}

export default Watermark;