export const images = [
  {
    thumbnail: require('../../assets/portfolio/15a-felix-tangerine-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/15a-felix-tangerine-FULL.jpg'),
    alt: `close up of Felix's right shoulder, with a bit of his black tank top peeking out from dangling green ferns in the fore and background. The tattoo is a tangerine on a branch with a few leaves, soft greyscale shading, and a small sparkle accent in the bottom left.`
  },
  {
    thumbnail: require('../../assets/portfolio/14a-breealina-trillium-SMOL.png'),
    hiRes: require('../../assets/portfolio/14a-breealina-trillium-FULL.png'),
    alt: `Close up of Alina (left) and Bree (right) back of shoulders with matching tattoos. Alina is wearing a blue top and red cardigan, Bree a pale blue shirt, and they both stand in front of a wall of bright green ivy leaves. They have mirrored designs of a trillium flower, with white wavy petals of three and wide leaves of three at the bottom of an abstract brushstroke stem. A halo of shading outlines a blank space of a moon in the background. Two sparkles shimmer around the flower.`
  },
  {
    thumbnail: require('../../assets/portfolio/13a-Amy-persimmon-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/13a-Amy-persimmon-FULL.jpg'),
    alt: `close up photo of amys left shoulder as she faces off to the left, a bit of her blue face mask and black top showing, standing in front of a white and green leaf patterned curtain. Several tattoos cover her left arm, and the picture focuses on the top most piece of a branch of two persimmons and several leaves.`
  },
  {
    thumbnail: require('../../assets/portfolio/12a-Kuya-trumpet-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/12a-Kuya-trumpet-FULL.jpg'),
    alt: `close up of Kuya's right lower leg, with a fresh trumpet flower tattoo and curving, abstract brushstroke stem among many other existing tattoos.`
  },
  {
    thumbnail: require('../../assets/portfolio/11a-Evie-persimmon-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/11a-Evie-persimmon-FULL.jpg'),
    alt: `photo of the back of Evie’s left shoulder, where she is wearing a blue tank top, black jacket, and light brown hair is pulled up with a pale green claw clip that matches her green face mask. she stands in front of a tall, dark green leafy plant. on the left shoulder is a fresh tattoo of an abstract brushtroke branch with two persimmons and a few leaves with little hole-y imperfections. The tattoo is greyscale.`
  },
  {
    thumbnail: require('../../assets/portfolio/10a-Hannah-olive-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/10a-Hannah-olive-FULL.jpg'),
    alt: `close up of Hannah's legs as she pulls up one leg of her grey sweatpants to show a fresh olive branch tattoo, curved right above the kneecap.`
  },
  {
    thumbnail: require('../../assets/portfolio/9a-Oona-bellflower-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/9a-Oona-bellflower-FULL.jpg'),
    alt: `close up photo of oonas left shoulder with a fresh floral tattoo. a bit of her colorful red and blue tank top is visible from the corner, and she stands in front of a white and green leafy curtain. The tattoo is a sprig of bellflower, with the dark shaded flowers pointing downwards, and slender curved leaves and stems arch beneath them.`
  },
  {
    thumbnail: require('../../assets/portfolio/8a-Lorilei-sampaguita-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/8a-Lorilei-sampaguita-FULL.jpg'),
    alt: `picture of a fresh tattoo on Lorilei’s left arm, as she faces away from the camera. Lorilei is wearing a black tank top and denim jeans, standing in front of a greyish brown backdrop. The floral tattoo has a lotus blooming upwards in the center, and a curving vine of sampaguita growing from both sides and curving a bit around the arm. Sparkles adorn the edges of the tattoo.`
  },
  {
    thumbnail: require('../../assets/portfolio/7a-Erin-brushstroke-SMOL.jpg'),
    hiRes: require('../../assets/portfolio/7a-Erin-brushstroke-FULL.jpg'),
    alt: `Picture of Erin's right arm with a fresh botanical tattoo. A pair of leaves grow from a vertical but abstract black brushtroke stem.`
  },
  {
    thumbnail: require('../../assets/portfolio/1b-cody-fairy.jpg'),
    hiRes: require('../../assets/portfolio/1a-cody-fairy.jpg'),
    alt: `Picture of Cody standing in front of a dark green plant, and a dark backdrop, showing off a fresh tattoo on their left arm. Soft light is coming from a window to the left. The tattoo is of a Legend of Zelda fairy, with soft shading to emulate the glow from the fairy and a few soft sparkles surrounding the area. The wings are translucent and the fairy looks like they’re flying to the left.`
  },
  {
    thumbnail: require('../../assets/portfolio/2b-rebecka-monkeyflower.jpg'),
    hiRes: require('../../assets/portfolio/2a-rebecka-monkeyflower.jpg'),
    alt: `Close up photo of Rebecka’s left arm, with the sleeve of her black T-shirt rolled up and standing in front of a grey backdrop. The tattoo is a greyscale botanical illustration of sticky monkeyflower, one sprig that curves upwards in one swoop. Several flower bloom in front of the long leaves, ending with a few young flower buds atop and smaller new leaves. The flowers have a very light shading compared to the darker leaves.`
  },
  {
    thumbnail: require('../../assets/portfolio/3b-christina-honeysuckle.jpg'),
    hiRes: require('../../assets/portfolio/3a-christina-honeysuckle.jpg'),
    alt: `Close up photo of Christina’s right arm, with a tattoo a descending vine of japanese honeysuckle. The honeysuckle is a vine sweeping down towards the left, at the top starting with some softly shaded leaves, followed by a pair of pale flowers and long extended stamen, and ending at the left tip with two unopened buds.`
  },
  {
    thumbnail: require('../../assets/portfolio/4b-mengyuan-nushu.jpg'),
    hiRes: require('../../assets/portfolio/4a-mengyuan-nushu.jpg'),
    alt: `Image of a black lineart tattoo on the center of Mengyuans back, as she stands in front of a gray backdrop. The tattoo is of various flowers and brushstroke style characters forming a circle and are representations of a Chinese verse of poetry “本来无一物”. The characters themselves are not exact copies of the Chinese characters but are modeling the brushstroke style from the book NuShu. From the middle right of the circle is a cherry blossom 本. continuing clockwise is: lotus 来, gourd leaves 无, just brushstroke for 一, and tulip leaves for 物. Between each of the characters are one to two small dots to help fill in the shape of the circle.`
  },
  {
    thumbnail: require('../../assets/portfolio/5b-moya-coastalstrawb.jpg'),
    hiRes: require('../../assets/portfolio/5a-moya-coastalstrawb.jpg'),
    alt: `Close up of Moya’s left thigh, she is standing in front of a dark grey backdrop. the tattoo is a greyscale curving vine of strawberry leaves, fruits and flowers. The leaves have three leaflets with serrated edges. The top leaf has a five petaled flower peeking out, then trails towards the front of the thigh with another leaf with two buds and one baby fruit. The tattoo wraps lower and more center to the thigh, finishing off with a final leaf and two strawberries dangling on the side. The strawberries are smaller and rounder than the store strawbs we typically eat. The whole tattoo is decorated with small sparkle accents that give a slightly more ornamental feel to the whole piece. The leaves and fruits are shaded in with grey.`
  },
  {
    thumbnail: require('../../assets/portfolio/6b-cassidy-hydrangea.jpg'),
    hiRes: require('../../assets/portfolio/6a-cassidy-hydrangea.jpg'),
    alt: `Picture of Cassidy’s pulling up her grey pants to reveal a fresh floral tattoo on her right upper thigh, as she is standing in front of a grey backdrop. The tattoo is a fine line hydrangea, with the cluster of flowers bending over to the left, with the stem curving downwards to the right. Three leaves grow alternately in the stem.`
  },
  {
    thumbnail: require('../../assets/portfolio/portfolio-5-small.png'),
    hiRes: require('../../assets/portfolio/portfolio-5.png'),
    alt: `image of client's right forearm with a fine line poppy tattoo, with two flowers and two leaves`
  },
  {
    thumbnail: require('../../assets/portfolio/portfolio-4-small.png'),
    hiRes: require('../../assets/portfolio/portfolio-4.png'),
    alt: `image of client's right shoulder with a fine line floral tattoo of white champaca flowers and three leaves.`
  },
  {
    thumbnail: require('../../assets/portfolio/portfolio-3-small.png'),
    hiRes: require('../../assets/portfolio/portfolio-3.png'),
    alt: `A close up photo of a linework floral tattoo on my right thigh, against a black background. A trail of honeysuckle grows down to the inner thigh and curls up a little at the end, where some young buds are about to open. Further up the vine are pairs of small leaves and a pair of open flowers with wispy stamens.`
  },
];
