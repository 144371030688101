import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import { ReactComponent as EnvelopeIcon } from '../assets/icons/envelope.svg';
import { ReactComponent as YoutubeIcon } from '../assets/icons/youtube.svg';
import { Link } from "react-router-dom"

export const Socials = () => {
  return (
    <div className='socials-links'>
      <Link to='https://www.instagram.com/wistaria.ink/'>
        <InstagramIcon className='icon' width='25px'/>
      </Link>
      <Link to='https://www.youtube.com/channel/UC7W1NZfSk0cT3GdAWk9k-8g'>
        <YoutubeIcon className='icon' width='34px'/>
      </Link>
      <Link to='mailto:wistaria.ink@gmail.com'>
        <EnvelopeIcon className='icon' width='32px'/>
      </Link>
    </div>
  )
}